
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.shrink-overflow::v-deep {
    overflow: hidden;
    max-width: 100%;

    .v-btn__content {
        display: block;
        max-width: 100%;

        .no-overflow {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
